import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Divider, Grid, Modal, TextField } from "@mui/material";

import { getCurrentUser } from "&config/getCurrentUser";
import { SubmitButton } from "&styled/button/button.component";
import SnackbarComponent from "&styled/snackBar/snackbar.component";
import EditIcon from "&assets/icons/edit";
import DeleteIcon from "&assets/icons/delete";
import HistoryIcon from "&assets/icons/history";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import { RootState } from "&store/store";
import { warningAlert, successAlert, errorAlert } from "&config/swalGenerator";
import { merchantsActions } from "./merchants.slice";


type ReduxProps = ConnectedProps<typeof connector>;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "34px",
  p: 2,
};

const MerchantsComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getMerchants, deleteMerchant, toggleMerchantStatus, token } = props;
  const user: any = getCurrentUser(token);
  const viewMerchantPermissions = user?.role?.permissions?.MerchentManagement?.viewAll
  const { data } = state;
  const [copy, setCopy] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [modalData, setModalData] = React.useState<null | {
    sid: string;
    privateKey: string;
    publicKey: string;
    token: string;
    base64Encode: string;
  }>(null);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Account Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "accountBalance",
      headerName: "Account Balance",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => renderBtnToggle(row),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 200,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    if(viewMerchantPermissions) {
      getMerchants().then().catch((error)=>{
        errorAlert();
      });
    }
  }, [getMerchants, viewMerchantPermissions]);

  const onDelete = async (event, id: string) => {
    const value = await warningAlert();
    if (value) {
      try {
        await deleteMerchant(id);

        successAlert("Deleted", "Merchant has been deleted successfully!");
      } catch (ex) {
        errorAlert();
      }
    }
  };

  const toggleStatus = async (row) => {
    try {
      const params = {
        status: row.status === "Active" ? "Inactive" : "Active",
        name: row.name,
        _id: row._id,
      };
      await toggleMerchantStatus(params);
    } catch (ex) {
      alert("Something went wrong");
      console.log(ex);
    }
  };

  const renderBtnToggle = (row) => {
    return (
      <Box
        sx={{
          backgroundColor: row.status === "Active" ? "#ECECEC" : "#D6FFD9",
          color: row.status === "Active" ? "#727272" : "#0FA01A",
          width: "85px",
          textAlign: "center",
          borderRadius: "5px",
          padding: "5px",
          fontSize: "12px",
          lineHeight: "18px",
          cursor: "pointer",
        }}
        onClick={() => toggleStatus(row)}
      >
        {row.status === "Active" ? "Deactivate" : "Activate"}
      </Box>
    );
  };

  const renderBtnHistory = (row) => {
    return (
      <FabButton label="History" color="secondary" onClick={() => history.push(`/merchants/history/${row._id}`)}>
        <HistoryIcon />
      </FabButton>
    );
  };
  const renderButtons = (row) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        {user.userTypeId === "Walee" ? renderBtnConfidential(row) : null}
        {renderBtnAccountBalance(row)}
        {renderBtnEdit(row)}
        {renderBtnHistory(row)}
        {renderBtnDelete(row)}
      </Box>
    );
  };
  const renderBtnDelete = (row) => {
    return (
      <FabButton label="Delete" color="secondary" onClick={(e: React.MouseEvent) => onDelete(e, row._id)}>
        <DeleteIcon />
      </FabButton>
    );
  };
  const renderBtnAccountBalance = (row) => {
    return (
      <FabButton
        label="Add Account Balance"
        onClick={(e: React.MouseEvent) => history.push(`/merchants/account-balance/${row._id}`)}
      >
        <CurrencyExchangeIcon sx={{ color: "rgb(102, 49, 247)" }} />
      </FabButton>
    );
  };

  const renderBtnConfidential = (row) => {
    return (
      <FabButton
        label="Confidential"
        onClick={(e: React.MouseEvent) =>
          setModalData({
            sid: row.sid,
            privateKey: row.privateKey,
            publicKey: row.publicKey,
            token: row.token,
            base64Encode: Buffer.from(`${row.sid}:${row.token}`).toString("base64"),
          })
        }
      >
        <InfoOutlinedIcon sx={{ color: "rgb(102, 49, 247)" }} />
      </FabButton>
    );
  };

  const renderBtnEdit = (row) => {
    return (
      <FabButton label="Edit" onClick={(e: React.MouseEvent) => history.push(`/merchants/form/${row._id}`)}>
        <EditIcon />
      </FabButton>
    );
  };
  if(!viewMerchantPermissions) {
    return <h1>Permission Denied</h1>
  }
  return (
    <>
      {copy && <SnackbarComponent type="info" message={"Text copied successfully"} />}
      <Modal
        open={!!modalData}
        onClose={() => {
          setModalData(null);
        }}
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-modal"
      >
        <Box sx={style}>
          <Box
            sx={{
              color: "#2e2e2e",
              fontSize: "20px",
              fontWeight: "bold",
              marginY: "1rem",
            }}
          >
            Confidential Credentials
          </Box>
          <Divider />
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} display={"flex"} justifyContent="center" alignItems="center" gap={2}>
              <Box sx={{ fontWeight: "bold" }} width={"120px"}>
                SID
              </Box>
              <TextField
                value={`${modalData?.sid}`}
                disabled
                fullWidth
                InputProps={{
                  endAdornment: (
                    <ContentCopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(`${modalData?.sid}`);
                        setCopy(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent="center" alignItems="center" gap={2}>
              <Box sx={{ fontWeight: "bold" }} width={"120px"}>
                Public Key
              </Box>
              <TextField
                value={`${modalData?.publicKey}`}
                disabled
                fullWidth
                InputProps={{
                  endAdornment: (
                    <ContentCopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(`${modalData?.publicKey}`);
                        setCopy(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent="center" alignItems="center" gap={2}>
              <Box sx={{ fontWeight: "bold" }} width={"120px"}>
                Private Key
              </Box>
              <TextField
                value={`${modalData?.privateKey}`}
                disabled
                fullWidth
                InputProps={{
                  endAdornment: (
                    <ContentCopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(`${modalData?.privateKey}`);
                        setCopy(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent="center" alignItems="center" gap={2}>
              <Box sx={{ fontWeight: "bold" }} width={"120px"}>
                Token
              </Box>
              <TextField
                value={`${modalData?.token}`}
                disabled
                fullWidth
                InputProps={{
                  endAdornment: (
                    <ContentCopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(`${modalData?.token}`);
                        setCopy(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent="center" alignItems="center" gap={2}>
              <Box sx={{ fontWeight: "bold" }} width={"120px"}>
                Base64
              </Box>
              <TextField
                value={`${modalData?.base64Encode}`}
                disabled
                fullWidth
                InputProps={{
                  endAdornment: (
                    <ContentCopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(`${modalData?.base64Encode}`);
                        setCopy(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              marginY: "1rem",
              gap: 2,
              justifyContent: "center",
            }}
          >
            <SubmitButton
              title="Cancel"
              handlePress={(handleClose) => {
                setModalData(null);
              }}
            />
          </Box>
        </Box>
      </Modal>
      <PageHeader title="Merchants" onAdd={() => history.push("/merchants/form/new")} addText="Create Merchant" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent sx={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[25, 50, 100, 500]}
              getRowId={(row) => row._id}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.merchants,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getMerchants: merchantsActions.getMerchants,
  getMerchantRevenue: merchantsActions.getMerchantRevenue,
  deleteMerchant: merchantsActions.deleteMerchant,
  toggleMerchantStatus: merchantsActions.toggleMerchantStatus,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const MerchantsComponentRedux = connector(MerchantsComponent);

export { MerchantsComponentRedux as MerchantsComponent };
