import { combineReducers, Reducer } from "redux";
import { homeReducer } from "&features/home/home.slice";
import { loginReducer } from "&features/login/login.slice";
import { landingReducer } from "&features/landing/landing.slice";
import { languageReducer } from "../redux/language";
import { reportsReducer } from "&features/reports/reports.slice";
import { settlementReducer } from "&features/settlement/settlement.slice";
import { merchantsReducer } from "&features/merchants/merchants.slice";
import { storesReducer } from "&features/stores/stores.slice";
import { carriersReducer } from "&features/carriers/carriers.slice";
import { servicesReducer } from "&features/services/services.slice";
import { usersReducer } from "&features/users/users.slice";
import { rolesReducer } from "&features/roles/roles.slice";
import { subscriptionsReducer } from "&features/subscriptions/subscriptions.slice";
import { oneTinePurchaseReducer } from "&features/oneTinePurchase/oneTinePurchase.slice";
import { creditCardRedirectReducer } from "&features/creditCardRedirect/creditCardRedirect.slice";
import { hostedCheckoutReducer } from "&features/hostedCheckout/hostedCheckout.slice";
import { loanRequestsReducer } from "&features/loanRequests/loanRequests.slice";
import { productsReducer } from "&features/products/products.slice";
import { loanAccountBalanceReducer } from "&features/loanAccountBalance/loanAccountBalance.slice";
import { loanAccountBalanceDetailReducer } from "&features/loanAccountBalanceDetail/loanAccountBalanceDetail.slice";
import {checkoutLogsReducer} from '&features/checkoutLogs/checkoutLogs.slice';
import {checkoutRequestsReducer} from '&features/checkoutRequests/checkoutRequests.slice';
import {merchantTopupReducer} from '&features/merchantTopup/merchantTopup.slice';
import {blacklistsReducer} from '&features/blacklists/blacklists.slice'
import {vouchersReducer} from '&features/vouchers/vouchers.slice'

const createRootReducer = (routerReducer: Reducer) =>
  combineReducers({
    router: routerReducer,
    reports: reportsReducer,
    home: homeReducer,
    login: loginReducer,
    landing: landingReducer,
    language: languageReducer,
    settlement: settlementReducer,
    merchants: merchantsReducer,
    carriers: carriersReducer,
    services: servicesReducer,
    users: usersReducer,
    roles: rolesReducer,
    subscriptions: subscriptionsReducer,
    oneTimePurchase: oneTinePurchaseReducer,
    cards: creditCardRedirectReducer,
    checkout: hostedCheckoutReducer,
    loanRequests: loanRequestsReducer,
    products: productsReducer,
    loanAccounts: loanAccountBalanceReducer,
    loanAccountDetails: loanAccountBalanceDetailReducer,
    checkoutLogs: checkoutLogsReducer,
    checkoutRequests: checkoutRequestsReducer,
    merchantTopups: merchantTopupReducer,
    blacklists: blacklistsReducer,
    vouchers: vouchersReducer,
    stores: storesReducer,
  });
export default createRootReducer;
